import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { MAX_CAMERA_SPEED, PTZ_KEY_PRESET, PTZ_KEYBOARD, PTZ_KEYS, PTZ_KEYS_CONTROL, PTZ_STOP } from './PtzConstants';
import GamePadComponent from './components/GamePadComponent';
import { goToPresetPtzService, panTiltPtzService, zoomPtzService } from '../../../services/ptzService';
import { onError, onSuccess, transformPanTiltZoom } from '../../../utils/utils';

const PtzComponent = ({ cameraId, openPresetDialog }) => {
  useEffect(() => {
    window.removeEventListener('keydown', onDownHandler);
    window.removeEventListener('keyup', onUpHandler);

    window.addEventListener('keydown', onDownHandler);
    window.addEventListener('keyup', onUpHandler);

    return () => {
      window.removeEventListener('keydown', onDownHandler);
      window.removeEventListener('keyup', onUpHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPresetDialog]);

  const onDownHandler = (event) => {
    if (openPresetDialog) return;

    const { key } = event;
    const keyLowerCase = key.toLowerCase();

    if (!!PTZ_KEYS.find((k) => k === keyLowerCase)) {
      event.preventDefault();
    }

    if (PTZ_KEYS_CONTROL[keyLowerCase]) {
      controlCameraByKeys(PTZ_KEYS_CONTROL[keyLowerCase].type, PTZ_KEYS_CONTROL[keyLowerCase].value);
    } else if (PTZ_KEY_PRESET[key]) {
      const data = {
        preset_id: PTZ_KEY_PRESET[key].value,
      };
      goToPresetPtzService(cameraId, data, onSuccess, onError);
    }
  };

  const onUpHandler = (event) => {
    if (openPresetDialog) return;

    const { key } = event;
    const keyLowerCase = key.toLowerCase();

    if (!!Object.keys(PTZ_KEYS_CONTROL).find((k) => k === keyLowerCase)) {
      event.preventDefault();
    }

    if (PTZ_KEYS_CONTROL[keyLowerCase]) {
      controlCameraByKeys(PTZ_KEYS_CONTROL[keyLowerCase].type, PTZ_STOP);
    }
  };

  const controlCameraByKeys = (typeMoving, parameterPanTiltZoom) => {
    let parameters = {};
    switch (typeMoving) {
      case PTZ_KEYBOARD.map.pan: {
        parameters = {
          pan: transformPanTiltZoom(parameterPanTiltZoom, MAX_CAMERA_SPEED),
          tilt: transformPanTiltZoom(PTZ_STOP, MAX_CAMERA_SPEED),
        };
        panTiltPtzService(cameraId, parameters, onSuccess, onError);
        break;
      }
      case PTZ_KEYBOARD.map.tilt: {
        parameters = {
          pan: transformPanTiltZoom(PTZ_STOP, MAX_CAMERA_SPEED),
          tilt: transformPanTiltZoom(parameterPanTiltZoom, MAX_CAMERA_SPEED),
        };
        panTiltPtzService(cameraId, parameters, onSuccess, onError);
        break;
      }
      case PTZ_KEYBOARD.map.zoom: {
        parameters = {
          zoom: transformPanTiltZoom(parameterPanTiltZoom, MAX_CAMERA_SPEED),
        };
        zoomPtzService(cameraId, parameters, onSuccess, onError);
        break;
      }
      default: {
        return null;
      }
    }
  };

  return <GamePadComponent cameraId={cameraId} />;
};

PtzComponent.propTypes = {
  cameraId: PropTypes.string.isRequired,
};

export default PtzComponent;
