import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { filterCameraMosaicActionCreator } from '../../../../actions/commonActions';
import { ROUTE_DEPLOYMENT_MOSAIC } from '../../../../utils/routes';
import { buildUrl } from '../../../../utils/axiosRequests';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { LEMBRA_SOURCE_STATE_RUNNING, LOCAL_STORAGE__FILTERED_CAMERA_LIST } from '../../../../utils/constants';
import { getStreamSourcePromise } from '../../../../services/commonServices';

const useStyles = makeStyles((theme) => ({
  map: {
    height: '400px',
    overflow: 'hidden',
  },
  onIcon: {
    color: theme.palette.primary.main,
    filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5))',
  },
  offIcon: {
    color: theme.palette.secondary.main,
    filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3))',
  },
}));

const MapComponent = ({ deploymentLocation, handleSetLoading }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { deployment_id } = useParams();

  const MAX_DECIMALS = 6;
  const POPUP_MARGINS = '0px 0px 0px 0px';
  const DEFAULT_ZOOM = 15;

  const cameraList = useSelector((state) => state.commonState.cameraList[deployment_id]) || [];
  const [map, setMap] = useState(null);
  const [markersLayer, setMarkersLayer] = useState(null);

  const onIcon = L.divIcon({
    className: classes.onIcon,
    html: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 607.38 823.36" width="42" height="42">
      <path d="M512.43,86.47A295.19,295.19,0,0,0,95,86.47C-20.34,201.76-35.48,364.55,76.29,529.75c56.74,83.88,227.4,293.61,227.4,293.61s170.65-209.73,227.4-293.61C642.86,364.55,627.72,201.76,512.43,86.47ZM303.69,481.34A177.65,177.65,0,1,1,481.34,303.69,177.65,177.65,0,0,1,303.69,481.34Z"
        fill="currentColor"/>
    </svg>`,
    iconSize: [42, 42],
    iconAnchor: [21, 42],
  });

  const offIcon = L.divIcon({
    className: classes.offIcon,
    html: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 607.38 823.36" width="42" height="42">
      <path d="M512.43,86.47A295.19,295.19,0,0,0,95,86.47C-20.34,201.76-35.48,364.55,76.29,529.75c56.74,83.88,227.4,293.61,227.4,293.61s170.65-209.73,227.4-293.61C642.86,364.55,627.72,201.76,512.43,86.47ZM303.69,481.34A177.65,177.65,0,1,1,481.34,303.69,177.65,177.65,0,0,1,303.69,481.34Z"
        fill="currentColor"/>
    </svg>`,
    iconSize: [42, 42],
    iconAnchor: [21, 42],
  });

  const filterCameraMosaic = useCallback(
    (cameras) => {
      dispatch(filterCameraMosaicActionCreator({ deployment_id, filteredCameraList: cameras }));
      localStorage.setItem(
        LOCAL_STORAGE__FILTERED_CAMERA_LIST,
        JSON.stringify({ deployment_id, filteredCameraList: cameras }),
      );
    },
    [dispatch, deployment_id],
  );

  const handleGoToCamera = (camera) => {
    filterCameraMosaic([camera]);
    const url = buildUrl(ROUTE_DEPLOYMENT_MOSAIC, { deployment_id });
    history.push(url);
  };

  const centerOnDeployment = () => {
    if (deploymentLocation && map) {
      map.setView([deploymentLocation.latitude, deploymentLocation.longitude], DEFAULT_ZOOM);
      handleSetLoading(false);
    }
  };

  useEffect(() => {
    if (!map) {
      const newMap = L.map('map').setView([0, 0], 2);
      setMap(newMap);

      L.tileLayer('https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        maxZoom: 19,
        attribution: 'Tiles &copy; Esri',
      }).addTo(newMap);
    }
  }, [map]);

  useEffect(() => {
    if (!!map && !!deploymentLocation) {
      if (!!cameraList?.length) {
        const group = L.featureGroup();

        const fetchCameraStatus = async (camera) => {
          const response = await getStreamSourcePromise(deployment_id, camera._id, true);
          const lembraStatus = response.data.lembra_source.status;

          const camMarker = L.marker([camera.place.geo.latitude, camera.place.geo.longitude], {
            icon: lembraStatus === LEMBRA_SOURCE_STATE_RUNNING ? onIcon : offIcon,
          });

          const popupContent = document.createElement('div');
          const link = document.createElement('span');
          link.innerHTML = camera.description;
          link.style.cursor = 'pointer';
          link.style.color = 'blue';
          link.style.textDecoration = 'underline';
          link.onclick = () => handleGoToCamera(camera);

          const latitudeText = document.createElement('p');
          latitudeText.innerHTML = `${camera.place.geo.latitude.toFixed(MAX_DECIMALS)} ${intl.formatMessage({ id: 'cardinal_north' })}`;
          latitudeText.style.margin = POPUP_MARGINS;

          const longitudeText = document.createElement('p');
          longitudeText.innerHTML = `${camera.place.geo.longitude.toFixed(MAX_DECIMALS) * -1} ${intl.formatMessage({ id: 'cardinal_west' })}`;
          longitudeText.style.margin = POPUP_MARGINS;

          popupContent.appendChild(link);
          popupContent.appendChild(latitudeText);
          popupContent.appendChild(longitudeText);
          camMarker.bindPopup(popupContent, { closeButton: false });

          group.addLayer(camMarker);

          if (group.getLayers().length) {
            if (markersLayer) {
              markersLayer.clearLayers();
              markersLayer.addLayer(group);
            } else {
              group.addTo(map);
              setMarkersLayer(group);
            }

            map.fitBounds(group.getBounds(), { padding: [50, 50] });
          } else {
            centerOnDeployment();
          }

          handleSetLoading(false);
        };

        for (const camera of cameraList) {
          if (!!camera?.place?.geo?.latitude && !!camera?.place?.geo?.longitude) {
            void fetchCameraStatus(camera);
          } else {
            centerOnDeployment();
            break;
          }
        }
      } else {
        centerOnDeployment();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, cameraList.length, deploymentLocation]);

  return <div id='map' className={classes.map} />;
};

export default MapComponent;
