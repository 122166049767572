import React from 'react';
import TitlePageComponent from './components/TitlePageComponent';
import BreadCrumbsComponent from './components/BreadCrumbsComponent';
import PropTypes from 'prop-types';
import { useStyles } from './PageHeaderStyles';
import Hidden from '@material-ui/core/Hidden';
import AuthorizedElement from '../../shared/auth/AuthorizedElement';
import {
  BREADCRUMB_VIEW_LINKS,
  BREADCRUMB_MANAGEMENT,
  BREADCRUMB_PROFILE,
  PERMISSION_CUSTOMER_VIEW,
  PERMISSION_DEPLOYMENT_VIEW,
  BREADCRUMB_CUSTOMER_MOSAIC,
} from '../../../utils/constants';
import { Link } from 'react-router-dom';
import {
  ROUTE_CAMERA,
  ROUTE_CUSTOMER,
  ROUTE_CUSTOMER_LIST,
  ROUTE_DEPLOYMENT,
  ROUTE_DEPLOYMENT_GROUP_MOSAIC,
  ROUTE_DEPLOYMENT_LIST,
  ROUTE_DEPLOYMENT_MOSAIC,
} from '../../../utils/routes';
import {
  BREADCRUMB_CUSTOMER_lIST,
  BREADCRUMB_DEPLOYMENTS_DETAIL,
  BREADCRUMB_MOSAIC,
  BREADCRUMB_MOSAIC_AND_CAMERA,
  BREADCRUMB_SERVICE_DEPLOYMENT_LIST,
  BREADCRUMB_TEMPORARY_VIEWER,
} from '../../../utils/constants';
import { buildUrl } from '../../../utils/axiosRequests';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { formatDateTime } from '../../../utils/utils';
import { Typography } from '@material-ui/core';

const PageHeaderComponent = ({ type, titleOverride, date, button = undefined }) => {
  const intl = useIntl();
  const classes = new useStyles();
  let { camera_id, customer_id, deployment_id, mosaic_id } = useParams();
  const customer = customer_id ?? localStorage.getItem('customer');

  let breadCrumbs = undefined;
  let title = undefined;
  let subtitle = undefined;

  const getBreadCrumbs = () => {
    switch (type) {
      case BREADCRUMB_CUSTOMER_lIST:
        title = intl.formatMessage({ id: 'customer_list' });
        break;
      case BREADCRUMB_SERVICE_DEPLOYMENT_LIST:
        title = intl.formatMessage({ id: 'installation_list' });
        breadCrumbs = (
          <span>
            <AuthorizedElement permission={PERMISSION_CUSTOMER_VIEW}>
              <Link className={classes.link} to={ROUTE_CUSTOMER_LIST}>
                {intl.formatMessage({ id: 'customer_list' })}
              </Link>
              <span> {'> '} </span>
            </AuthorizedElement>
            <AuthorizedElement permission={PERMISSION_DEPLOYMENT_VIEW}>
              <Link
                className={classes.link}
                to={customer ? buildUrl(ROUTE_CUSTOMER, { customer_id: customer }) : ROUTE_DEPLOYMENT_LIST}
              >
                {intl.formatMessage({ id: 'installation_list' })}
              </Link>
            </AuthorizedElement>
          </span>
        );
        break;
      case BREADCRUMB_DEPLOYMENTS_DETAIL:
        title = intl.formatMessage({ id: 'deployment_detail' });
        breadCrumbs = (
          <span>
            <AuthorizedElement permission={PERMISSION_CUSTOMER_VIEW}>
              <Link className={classes.link} to={ROUTE_CUSTOMER_LIST}>
                {intl.formatMessage({ id: 'customer_list' })}
              </Link>
              <span> {'> '}</span>
            </AuthorizedElement>
            <AuthorizedElement permission={PERMISSION_DEPLOYMENT_VIEW}>
              <Link
                className={classes.link}
                to={customer ? buildUrl(ROUTE_CUSTOMER, { customer_id: customer }) : ROUTE_DEPLOYMENT_LIST}
              >
                {intl.formatMessage({ id: 'installation_list' })}
              </Link>
            </AuthorizedElement>
            <span> {'> '}</span>
            <Link className={classes.link} to={buildUrl(ROUTE_DEPLOYMENT, { deployment_id })}>
              {intl.formatMessage({ id: 'deployment_detail' })}
            </Link>
          </span>
        );
        break;
      case BREADCRUMB_MOSAIC:
        title = intl.formatMessage({ id: 'mosaic' });
        breadCrumbs = (
          <span>
            <AuthorizedElement permission={PERMISSION_CUSTOMER_VIEW}>
              <Link className={classes.link} to={ROUTE_CUSTOMER_LIST}>
                {intl.formatMessage({ id: 'customer_list' })}
              </Link>
              <span> {'> '}</span>
            </AuthorizedElement>
            <AuthorizedElement permission={PERMISSION_DEPLOYMENT_VIEW}>
              <Link
                className={classes.link}
                to={customer ? buildUrl(ROUTE_CUSTOMER, { customer_id: customer }) : ROUTE_DEPLOYMENT_LIST}
              >
                {intl.formatMessage({ id: 'installation_list' })}
              </Link>
            </AuthorizedElement>
            <span> {'> '}</span>
            <Link className={classes.link} to={buildUrl(ROUTE_DEPLOYMENT_GROUP_MOSAIC, { mosaic_id })}>
              {intl.formatMessage({ id: 'mosaic' })}
            </Link>
          </span>
        );
        break;
      case BREADCRUMB_MOSAIC_AND_CAMERA:
        const BREAD_CRUMBS_CAMERA = !!camera_id && (
          <>
            <span> {'> '}</span>
            <Link className={classes.link} to={buildUrl(ROUTE_CAMERA, { deployment_id, camera_id })}>
              {intl.formatMessage({ id: 'camera' })}
            </Link>
          </>
        );
        title = !camera_id ? intl.formatMessage({ id: 'mosaic' }) : intl.formatMessage({ id: 'camera' });
        breadCrumbs = (
          <span>
            <AuthorizedElement permission={PERMISSION_CUSTOMER_VIEW}>
              <Link className={classes.link} to={ROUTE_CUSTOMER_LIST}>
                {intl.formatMessage({ id: 'customer_list' })}
              </Link>
              <span> {'> '} </span>
            </AuthorizedElement>
            <AuthorizedElement permission={PERMISSION_DEPLOYMENT_VIEW}>
              <Link
                className={classes.link}
                to={customer ? buildUrl(ROUTE_CUSTOMER, { customer_id: customer }) : ROUTE_DEPLOYMENT_LIST}
              >
                {intl.formatMessage({ id: 'installation_list' })}
              </Link>
            </AuthorizedElement>
            <span> {'> '}</span>
            <Link className={classes.link} to={buildUrl(ROUTE_DEPLOYMENT, { deployment_id })}>
              {intl.formatMessage({ id: 'deployment_detail' })}
            </Link>
            <span>
              <span> {'> '}</span>
              <Link className={classes.link} to={buildUrl(ROUTE_DEPLOYMENT_MOSAIC, { deployment_id })}>
                {intl.formatMessage({ id: 'mosaic' })}
              </Link>
            </span>
            {BREAD_CRUMBS_CAMERA}
          </span>
        );
        break;
      case BREADCRUMB_CUSTOMER_MOSAIC:
        title = intl.formatMessage({ id: 'mosaic' });
        breadCrumbs = (
          <span>
            <AuthorizedElement permission={PERMISSION_CUSTOMER_VIEW}>
              <Link className={classes.link} to={ROUTE_CUSTOMER}>
                {intl.formatMessage({ id: 'customer_list' })}
              </Link>
              <span> {'> '} </span>
            </AuthorizedElement>
            <AuthorizedElement permission={PERMISSION_DEPLOYMENT_VIEW}>
              <Link
                className={classes.link}
                to={customer ? buildUrl(ROUTE_CUSTOMER, { customer_id: customer }) : ROUTE_DEPLOYMENT_LIST}
              >
                {intl.formatMessage({ id: 'installation_list' })}
              </Link>
            </AuthorizedElement>
          </span>
        );
        break;
      case BREADCRUMB_PROFILE:
        title = intl.formatMessage({ id: 'user_profile' });
        break;
      case BREADCRUMB_MANAGEMENT:
        title = intl.formatMessage({ id: 'user_management' });
        break;
      case BREADCRUMB_VIEW_LINKS:
        title = intl.formatMessage({ id: 'view_link__management' });
        break;
      case BREADCRUMB_TEMPORARY_VIEWER:
        title = titleOverride;
        if (!!date) {
          subtitle = `${intl.formatMessage({
            id: 'view_link__valid_date',
          })} - ${formatDateTime(date)}`;
        }
        break;
      default:
        break;
    }
  };

  getBreadCrumbs();

  return (
    <div className={classes.headerContainer}>
      <div className={classes.root}>
        <TitlePageComponent title={title} classes={classes} />
        <Hidden mdDown>
          <div>{breadCrumbs && <BreadCrumbsComponent breadCrumbs={breadCrumbs} classes={classes} />}</div>
        </Hidden>
        {!!subtitle && (
          <Typography variant='h5' className={classes.subtitle}>
            {subtitle}
          </Typography>
        )}
      </div>
      <div>{button}</div>
    </div>
  );
};

PageHeaderComponent.propTypes = {
  type: PropTypes.string.isRequired,
};

export default PageHeaderComponent;
