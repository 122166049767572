import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  getPresetsPtzService,
  editPresetPtzService,
  savePresetPtzService,
  deletePresetPtzService,
} from '../../../services/ptzService';
import { PRESET_BUTTONS, PRESETS_CONTROLS } from '../ptz/PtzConstants';
import { showMessage } from '../../shared/notificationSnack/NotificationSnack';
import { onError } from '../../../utils/utils';
import { tenantPrimaryButton, tenantSecondaryButton } from '../../../utils/tenantStyles';
import ControlCameraRoundedIcon from '@material-ui/icons/ControlCameraRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  actionButtons: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  select: {
    width: '50%',
    flexGrow: 1,
  },
  name: {
    marginLeft: theme.spacing(2),
    width: '50%',
    flexGrow: 2,
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  primaryButton: tenantPrimaryButton(theme),
  secondaryButton: tenantSecondaryButton(theme),
}));

const PresetDialog = ({ camera, handleSetPresets, keyboardDevice }) => {
  const intl = useIntl();
  const classes = useStyles();

  const presets = useSelector((state) => state.ptzState.presets);

  const [open, setOpen] = useState(false);
  const [button, setButton] = useState('');
  const [presetName, setPresetName] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setButton('');
    setPresetName('');
  };

  const handleButtonSelected = (event) => {
    const button = event.target.value;
    setButton(button);
    try {
      const gamepadButton = keyboardDevice ? PRESETS_CONTROLS.find((p) => p[0] === button)[1] : button;
      const [preset] = presets.filter((_preset) => _preset.button === gamepadButton);
      const buttonIndex = PRESET_BUTTONS.indexOf(gamepadButton);
      setPresetName(!!preset ? preset.name : `Pos${buttonIndex + 1}`);
    } catch (e) {
      // Intentionally left blank
    }
  };

  const handleEditPresetResponse = (presetName, presetPos) => {
    getPresetsPtzService(camera._id, handleSetPresets, onError);
    showMessage(`Preset ${presetName} saved in position ${presetPos}.`);
  };

  const handleDeletePresetResponse = (presetPos) => {
    getPresetsPtzService(camera._id, handleSetPresets, onError);
    showMessage(`Preset in position ${presetPos} deleted.`);
  };

  const editPreset = (presetName, presetPos) => {
    const data = { name: presetName };
    editPresetPtzService(camera._id, data, presetPos, () => handleEditPresetResponse(presetName, presetPos), onError);
  };

  const savePreset = (presetName, presetPos) => {
    savePresetPtzService(camera._id, presetPos, () => editPreset(presetName, presetPos), onError);
  };

  const handleSavePreset = () => {
    const gamepadButton = keyboardDevice ? PRESETS_CONTROLS.find((p) => p[0] === button)[1] : button;
    const index = PRESET_BUTTONS.indexOf(gamepadButton) + 1;
    if (index >= 0) {
      const position = index < 10 ? `0${index}` : index;
      savePreset(presetName, position);
      handleClose();
    } else {
      showMessage('Preset not found. Invalid button.');
    }
  };

  const deletePreset = (presetPos) => {
    deletePresetPtzService(camera._id, presetPos, () => handleDeletePresetResponse(presetPos), onError);
  };

  const handleDeletePreset = () => {
    const gamepadButton = keyboardDevice ? PRESETS_CONTROLS.find((p) => p[0] === button)[1] : button;
    const index = PRESET_BUTTONS.indexOf(gamepadButton);
    if (index >= 0) {
      const position = index < 9 ? `0${index + 1}` : index + 1;
      deletePreset(position);
      handleClose();
    } else {
      showMessage('Preset not found. Invalid button.');
    }
  };

  const handleSetPresetName = (e) => {
    setPresetName(e.target.value);
  };

  return (
    <div>
      <Button startIcon={<ControlCameraRoundedIcon />} className={classes.primaryButton} onClick={handleClickOpen}>
        <Typography variant={'body2'}>
          <FormattedMessage id='preset_dialog_open_modal_button' />
        </Typography>
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
        <DialogTitle>{intl.formatMessage({ id: 'preset_dialog_open_modal_button' })}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='preset_dialog_content_text' />
          </DialogContentText>
          <div className={classes.form}>
            <FormControl className={classes.select}>
              <InputLabel>
                <FormattedMessage id='preset_dialog_select_preset_button' />
              </InputLabel>
              <Select value={button} onChange={handleButtonSelected}>
                {PRESETS_CONTROLS.map((p) => (
                  <MenuItem key={p[1]} value={p[keyboardDevice ? 0 : 1]}>
                    {p[keyboardDevice ? 0 : 1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              className={classes.name}
              disabled={true}
              value={presetName}
              onChange={handleSetPresetName}
              margin='dense'
              id='name'
              label={<FormattedMessage id='preset_dialog_select_preset_name' />}
              type='text'
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button startIcon={<CloseRoundedIcon />} className={classes.secondaryButton} onClick={handleClose}>
            <Typography variant={'body2'}>
              <FormattedMessage id='preset_dialog_cancel_button' />
            </Typography>
          </Button>
          <div className={classes.actionButtons}>
            <Button
              startIcon={<DeleteForeverRoundedIcon />}
              disabled={!presetName}
              className={classes.primaryButton}
              onClick={handleDeletePreset}
            >
              <Typography variant={'body2'}>
                <FormattedMessage id='preset_dialog_delete_button' />
              </Typography>
            </Button>
            <Button
              disabled={!presetName}
              startIcon={<SaveRoundedIcon />}
              className={classes.primaryButton}
              onClick={handleSavePreset}
            >
              <Typography variant={'body2'}>
                <FormattedMessage id='preset_dialog_save_button' />
              </Typography>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

PresetDialog.propTypes = {
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.any,
      name: PropTypes.string,
      button: PropTypes.string,
    }),
  ),
  home: PropTypes.shape({
    index: PropTypes.any,
    name: PropTypes.string,
    button: PropTypes.string,
  }),
  savePreset: PropTypes.func,
  deletePreset: PropTypes.func,
};

export default PresetDialog;
