import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import ViewCompactRoundedIcon from '@material-ui/icons/ViewCompactRounded';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import CameraTableComponent from './components/cameraTableComponent/CameraTableComponent';
import DeploymentCardComponent from './components/deploymentCardComponent/DeploymentCardComponent';
import {
  getDeployments,
  getServiceContractService,
  getSupervisionServiceContractService,
  getRecordingPolicy,
} from '../../services/commonServices';
import PageHeaderComponent from '../common/pageHeaderComponent/PageHeaderComponent';
import {
  BREADCRUMB_DEPLOYMENTS_DETAIL,
  LOCAL_STORAGE__CUSTOMER_ID,
  LOCAL_STORAGE__FILTERED_CAMERA_LIST,
} from '../../utils/constants';
import { fetchDeploymentCameras, filterCameraMosaicActionCreator } from '../../actions/commonActions';
import withAuthorization from '../shared/auth/withAuthorization';
import { GRAY } from '../../utils/constants';
import { PERMISSION_DEPLOYMENT_VIEW, PERMISSION_RECORDING_VIEW } from '../../utils/constants';
import AlertsContainer from '../deploymentListComponent/components/alertsContainer/AlertsContainer';
import { tenantPrimaryButton } from '../../utils/tenantStyles';
import MapComponent from './components/mapComponent/MapComponent';
import ViewModuleRoundedIcon from '@material-ui/icons/ViewModuleRounded';
import { buildUrl } from '../../utils/axiosRequests';
import { ROUTE_DEPLOYMENT_MOSAIC } from '../../utils/routes';
import AuthorizedElement from '../shared/auth/AuthorizedElement';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import Paper from '@material-ui/core/Paper';
import Spinner from '../shared/loading/components/Spinner';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100vh - 130px)',
    display: 'flex',
    flexDirection: 'column',
  },
  iframeContainer: {
    height: '100%',
    position: 'relative',
  },
  iframeButton: {
    ...tenantPrimaryButton(theme),
    height: '50px',
    position: 'absolute',
    bottom: 20,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      right: 'auto',
      bottom: 50,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  root: {
    zIndex: 0,
    padding: theme.spacing(0, 20),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6),
      marginBottom: theme.spacing(6),
    },
  },
  infoTables: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    color: GRAY,
  },
  cameraHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  flexTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(-10),
  },
  deploymentDetails: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  tableContainer: {
    width: '100%',
    marginBottom: theme.spacing(12),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },
  mapContainer: {
    width: '100%',
    marginBottom: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    position: 'relative',
  },
  spinnerContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
    zIndex: 999,
    width: '100%',
    height: '100%',
  },
  primaryButton: tenantPrimaryButton(theme),
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  paper: {
    width: '100%',
  },
}));

const DeploymentComponent = (locale) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const classes = useStyles();
  const { deployment_id } = useParams();
  const history = useHistory();

  const customerId = localStorage.getItem(LOCAL_STORAGE__CUSTOMER_ID);

  const getState = useSelector((state) => state.commonState);
  const cameraList = useSelector((state) => state.commonState.cameraList[deployment_id]) || [];

  const [recordingPolicy, setRecordingPolicy] = useState(null);
  const [deployment, setDeployment] = useState(null);
  const [deploymentLocation, setDeploymentLocation] = useState(null);
  const [serviceContact, setServiceContact] = useState(true);

  const [operationPanel, setOperationPanel] = useState(null);

  const getPolicy = useCallback(() => {
    const getRecordingPolicySuccess = (response) => {
      setRecordingPolicy(response.data);
    };
    getRecordingPolicy(deployment_id, undefined, getRecordingPolicySuccess);
  }, [deployment_id]);

  const getFilteredCameras = () => getState.filteredCameraList[deployment_id] || [];

  const handleSetDeploymentState = (data) => {
    setDeployment(data);
    setDeploymentLocation(data?.place?.geo);
  };

  const callDeployments = useCallback(() => {
    if (initialized && keycloak) {
      getDeployments(keycloak, deployment_id, handleSetDeploymentState);
    }
  }, [initialized, keycloak, deployment_id]);

  const callCameras = useCallback(() => {
    if (deployment_id) {
      dispatch(fetchDeploymentCameras(deployment_id));
    }
  }, [deployment_id, dispatch]);

  const supervisionContractCallback = (response) => {
    setOperationPanel(response.data?.operation_panel);
  };

  const supervisionContractErrorCallback = (response) => void 0;

  const getServiceContract = useCallback(() => {
    const serviceContractCallback = (response) => {
      if (!response?.data?.length) {
        return;
      }

      const serviceContract = response.data[0];

      setServiceContact(serviceContract._id);

      getSupervisionServiceContractService(
        serviceContract._id,
        supervisionContractCallback,
        supervisionContractErrorCallback,
      );
    };

    if (deployment_id) {
      getServiceContractService(deployment_id, serviceContractCallback);
    }
  }, [deployment_id]);

  useEffect(() => {
    callDeployments();
  }, [callDeployments]);

  useEffect(() => {
    callCameras();
  }, [callCameras]);

  useEffect(() => {
    getPolicy();
  }, [getPolicy]);

  useEffect(() => {
    getServiceContract();
  }, [getServiceContract]);

  const [displayIframe, setDisplayIframe] = useState(false);

  const handleGoToPanel = () => {
    setDisplayIframe(true);
  };

  const handleCloseIframe = () => {
    setDisplayIframe(false);
  };

  const filterCameraMosaic = useCallback(
    (cameras) => {
      dispatch(
        filterCameraMosaicActionCreator({
          deployment_id,
          filteredCameraList: cameras,
        }),
      );
      localStorage.setItem(
        LOCAL_STORAGE__FILTERED_CAMERA_LIST,
        JSON.stringify({ deployment_id, filteredCameraList: cameras }),
      );
    },
    [dispatch, deployment_id],
  );

  const handleGoToMosaic = (deployment_id) => {
    const filteredCameraList = getFilteredCameras();
    if (filteredCameraList.length === 0) {
      filterCameraMosaic(cameraList);
    }
    const url = buildUrl(ROUTE_DEPLOYMENT_MOSAIC, { deployment_id });
    history.push(url);
  };

  const iframeRef = useRef(null);

  const newUrl = `${operationPanel?.url}?access=keycloak&service_contract=${serviceContact}&deployment=${deployment_id}`;

  const [mapLoading, setMapLoading] = useState(true);

  const handleSetMapLoading = (value) => {
    setMapLoading(value);
  };

  return (
    <div className={classes.container}>
      {displayIframe ? (
        <div className={classes.iframeContainer}>
          <Button startIcon={<CancelPresentationIcon />} className={classes.iframeButton} onClick={handleCloseIframe}>
            <Typography variant={'body2'}>{intl.formatMessage({ id: 'global_buttons_close' })}</Typography>
          </Button>
          <iframe
            title={'nuuk-iframe'}
            id={'nuuk-iframe'}
            ref={iframeRef}
            src={newUrl}
            height={'100%'}
            width={'100%'}
            allow='fullscreen; display-capture; geolocation; notifications'
          />
        </div>
      ) : (
        <Grid className={classes.root}>
          <PageHeaderComponent type={BREADCRUMB_DEPLOYMENTS_DETAIL} />

          <Grid container spacing={6} className={classes.infoTables}>
            <Grid item md={12} lg={4} className={classes.deploymentDetails}>
              <Typography variant='h5' align='left' className={classes.subtitle}>
                {intl.formatMessage({ id: 'details' })}
              </Typography>
              <DeploymentCardComponent deployment={deployment} recordingPolicy={recordingPolicy} />
            </Grid>
            <Grid item md={12} lg={8}>
              <div className={`${classes.cameraHeader} ${classes.subtitle}`}>
                <Typography variant='h5'>{intl.formatMessage({ id: 'deployment_camera_map' })}</Typography>
              </div>
              <div className={classes.mapContainer}>
                {mapLoading && (
                  <div className={classes.spinnerContainer}>
                    <Spinner />
                  </div>
                )}
                <MapComponent
                  deploymentLocation={deploymentLocation}
                  locale={locale}
                  handleSetLoading={handleSetMapLoading}
                />
              </div>
              <div className={`${classes.cameraHeader} ${classes.subtitle}`}>
                <Typography variant='h5'>{intl.formatMessage({ id: 'deployment_camera_list' })}</Typography>
                <div className={classes.buttonContainer}>
                  {operationPanel?.redirection_enabled && (
                    <Button
                      className={classes.primaryButton}
                      onClick={handleGoToPanel}
                      startIcon={<ViewCompactRoundedIcon />}
                    >
                      <Typography variant={'button'}>{intl.formatMessage({ id: 'go_to_panel' })}</Typography>
                    </Button>
                  )}
                  <Button
                    className={classes.primaryButton}
                    onClick={() => handleGoToMosaic(deployment_id)}
                    startIcon={<ViewModuleRoundedIcon />}
                  >
                    <Typography variant={'button'}>{intl.formatMessage({ id: 'see_mosaic' })}</Typography>
                  </Button>
                </div>
              </div>
              <div className={classes.tableContainer}>
                <Paper className={classes.paper} elevation={0}>
                  <CameraTableComponent
                    filteredCameraList={getFilteredCameras()}
                    filterCameraMosaic={filterCameraMosaic}
                    handleGoToMosaic={handleGoToMosaic}
                  />
                </Paper>
                <AuthorizedElement permission={PERMISSION_RECORDING_VIEW}>
                  <Paper className={classes.paper} elevation={0}>
                    <AlertsContainer customerId={customerId} deploymentId={deployment_id} locale={locale} />
                  </Paper>
                </AuthorizedElement>
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default withAuthorization(PERMISSION_DEPLOYMENT_VIEW)(DeploymentComponent);
